import ApiRequestService from "@/services/ApiRequestService";

export default class LanguagesService {
  static languages = null
  static DEFAULT_LANG = 'en'
  languagesFromApi = [];

  static setLanguage(key, value) {
    this.languages.push({
      [key]: value,
    })
  }

  static getLanguagesKeysList() {
    let language = !this.languages ? '' : this.languages.reduce((acc, item) => {
      let key = Object.keys(item)[0];
      acc.push(key)
      return acc;
    },[])

    return language
  }

  static getLanguagesValuesList() {
    let language = !this.languages ? '' : this.languages.reduce((acc, item) => {
      let value = Object.values(item)[0];
      acc.push(value)
      return acc;
    },[])

    return language
  }

  static getLanguagesList() {
    return this.languages
  }

  static getDefaultLanguage(){
    let userLanguage = navigator.language || navigator.userLanguage;
    let languagesISO2 = LanguagesService.getLanguagesValuesList()

    for(let i = 0; i < languagesISO2.length; i++){
      if(languagesISO2[i] === userLanguage){
        return Object.keys(this.languages[i])[0]
      }
    }

    return LanguagesService.DEFAULT_LANG
  }

  async setLanguages(){
    LanguagesService.languages = await this.getLanguagesListFromApi();
    return LanguagesService.languages;
  }
  async getLanguagesList(){
    return LanguagesService.languages;
  }
  async getLanguagesListFromApi() {
    return ApiRequestService.getRequest( 'api/admin-language/all');
  }
}
