
// eslint-disable-next-line object-curly-newline
import {
  // mdiFacebook,
  // mdiTwitter,
  // mdiGithub,
  // mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'

import { ref } from '@vue/composition-api'
import SnippetsService from '@/services/SnippetsService'
import LanguagesService from '@/services/LanguagesService'
import StorageService, { EMAIL, LANGUAGE_KEY } from '@/services/StorageService'
import { DataEmitterService } from '@/services/DataEmiterService'
import AuthenticationService from '@/services/AuthenticationService'

export default {
  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage( 'Login', StorageService.getFromStorage( LANGUAGE_KEY ) || 'en' )
    DataEmitterService.$emit( 'showLoader', false );

    return {
      loginSnippet: snippets ?? {},
      emailIsValidErr: true,
      passwordIsValidErr: true,
      languageIsValidErr: true,
      emailError: '',
      InvalidEmail: '',
      submitted: false,
      passwordError: '',
      languageError: '',
      languages: [LanguagesService.DEFAULT_LANG],
      language: '',
      defaultLanguageKey: LanguagesService.DEFAULT_LANG,
    }
  },

  methods: {

    selectLanguage( language ) {
      StorageService.setToStorage( LANGUAGE_KEY, language )
      DataEmitterService.$emit( 'language', language )
  },

  async login() {
    this.submitted = true
    //TODO: uncomment
    this.emailIsValidErr = this.emailIsValid( this.email )
    this.passwordIsValidErr = this.passwordIsValid( this.password )
    this.languageIsValidErr = this.languageIsValid( this.defaultLanguageKey )


    if ( this.emailIsValidErr !== true || this.passwordIsValidErr !== true || this.languageIsValidErr !== true ) {
      this.passwordError = this.passwordIsValidErr
      this.emailError = this.emailIsValidErr
      this.languageError = this.languageIsValidErr
      return
    }

    this.selectLanguage( this.defaultLanguageKey )

    const authenticationData = {
      email: this.email,
      password: this.password,
    }

    StorageService.setToStorage( EMAIL, this.email )
    const isAuthenticated = await AuthenticationService.signIn( authenticationData );
    if ( isAuthenticated ) {
      this.passwordError = ''
      this.emailError = ''
      this.languageError = ''
      this.InvalidEmail = ''

      this.$store.commit( 'setAuthentication', true )
      DataEmitterService.$emit( 'show-correct-access')

      if (StorageService.getAllUserRoles().includes('Jury')) {
        this.$router.push( '/jury' )
        return
      }

      if (StorageService.getAllUserRoles().includes('SanityCheckAdmin')) {
        this.$router.push( '/sanity-check' )
        return
      }

      new LanguagesService().setLanguages().then();

      if(StorageService.getUserRoles() === 'FacilityManager'){
        this.$router.push( '/bookings' )
      }else{
        this.$router.push( '/users' )
      }

    } else {
      this.InvalidEmail = this.loginSnippet.login_invalid_data;
    }
  },

  emailIsValid: function ( v ) {
    if ( !( v && v.length > 0 ) ) {
      return this.loginSnippet.email_is_required
    }
    return ( v && ( !!v && !!v.match( /^.*.{1,}@.*.{1,}\..*.{1,}/ ) ) ) || this.loginSnippet.invalid_email
  },

  passwordIsValid: function ( v ) {
    return ( v && v.length > 0 ) || this.loginSnippet.password_is_required
  },

  languageIsValid: function ( v ) {
    return ( v && v.length > 0 ) || this.loginSnippet.language_is_required
  },
}
,
setup()
{
  const isPasswordVisible = ref( false )
  const email = ref( '' )
  const password = ref( '' )

  // const socialLink = [
  //   {
  //     icon: mdiFacebook,
  //     color: '#4267b2',
  //     colorInDark: '#4267b2',
  //   },
  //   {
  //     icon: mdiTwitter,
  //     color: '#1da1f2',
  //     colorInDark: '#1da1f2',
  //   },
  //   {
  //     icon: mdiGithub,
  //     color: '#272727',
  //     colorInDark: '#fff',
  //   },
  //   {
  //     icon: mdiGoogle,
  //     color: '#db4437',
  //     colorInDark: '#db4437',
  //   },
  // ]

  return {
    isPasswordVisible,
    email,
    password,

    // socialLink,

    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline,
    },
  }
}
,
}
